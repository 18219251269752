<template>
  <main>
    <Row :gutter="15">
      <Col :span="10">
        <ButtonGroup shape="circle">
          <Button icon="md-refresh" @click="loadData">刷新</Button>
          <Button icon="md-download" @click="exportFile"  v-if="Auth([ 'User', 'export' ])">导出</Button>
        </ButtonGroup>
      </Col>
      <Col :span="7" class="text-right">
        <DatePicker
          type="daterange"
          placeholder="注册时间"
          @on-change="filterDate"
          style="width: 250px"
        ></DatePicker>
      </Col>
      <Col :span="7" :class="{ mt: $store.getters.screen == 'xs' }">
        <searcher ref="searcher" :options="searches" @on-change="loadData"></searcher>
      </Col>
    </Row>

    <Divider/>
    <Table border :columns="columns" :data="data" @on-sort-change="changeSort"></Table>

    <Divider/>
    <pagination ref="pagination" :total="total" @on-change="loadData"/>
  </main>
</template>

<script>
import buildURL from "axios/lib/helpers/buildURL";
export default {
  data() {
    return {
      searches: {
        mobile: "手机号码",
        nickname: "微信昵称",
        id: "ID"
      },
      sort: "",
      total: 0,
      data: [],
      columns: [
        {
          title: "ID",
          key: "id",
          sortable: "custom",
          minWidth: 130,
          align: "center"
        },
        {
          title: "昵称",
          key: "nickname",
          sortable: "custom",
          align: "center",
          minWidth: 200,
          render: (h, params) => {
            return h(
              "a",
              {
                class: "flex flex-align-center",
                on: {
                  click: () => {
                    this.$router.push("/user/detail/" + params.row.id);
                  }
                }
              },
              [
                h("img", {
                  attrs: {
                    src: params.row.avatar
                      ? params.row.avatar
                      : require("@/assets/no-avatar.jpg"),
                    width:'48',
                    height:'48'
                  },
                  class: "avatar mr"
                }),
                h("p", params.row.nickname)
              ]
            );
          }
        },
        {
          title: "手机号码",
          minWidth: 130,
          key: "mobile",
          sortable: "custom",
          align: "center"
        },
        {
          title: "性别",
          minWidth: 100,
          key: "gender",
          sortable: "custom",
          align: "center",
          render: (h, params) => {
            return h("Icon", {
              props: {
                type: params.row.gender == 1 ? "md-male" : "md-female"
              },
              class: params.row.gender == 1 ? "text-blue" : "text-pink"
            });
          }
        },
        {
          title: "注册时间",
          minWidth: 140,
          key: "created_at",
          sortable: "custom",
          align: "center"
        },
        {
          title: "余额",
          minWidth: 80,
          key: "money",
          sortable: "custom",
          align:'center',
          render: (h, params) => {
            return h(
              "a",
              {
                class: "flex flex-align-center",
                on: {
                  click: () => {
                    this.$router.push(
                      "/user-money-records?user_id=" + params.row.id
                    );
                  },
                },
              },
              params.row.money+'元'
            );
          },
        },
        {
          title: "姓名",
          key: "full_name",
          sortable: "custom",
          minWidth: 100,
          align: "center",
          render: (h, params) => {
            return h(
              "span",
              params.row.full_name ? params.row.full_name : "无"
            );
          }
        },
        {
          title: "生日",
          key: "birth",
          sortable: "custom",
          minWidth: 100,
          align: "center",
          render: (h, params) => {
            return h("span", params.row.birth ? params.row.birth : "无");
          }
        },
        {
          title: "学历",
          key: "education",
          sortable: "custom",
          minWidth: 100,
          align: "center",
          render: (h, params) => {
            return h(
              "span",
              params.row.education ? params.row.education : "无"
            );
          }
        },
        {
          title: "会员有效期",
          key: "member_until",
          sortable: "custom",
          minWidth: 140,
          align: "center",
          render: (h, params) => {
            return h(
              "span",
              params.row.is_member ? params.row.member_until_date : "未激活"
            );
          }
        },
        {
          title: "状态",
          key: "blocked",
          sortable: "custom",
          minWidth: 100,
          align: "center",
          render: (h, params) => {
            return h("span", params.row.blocked ? "已封禁" : "正常");
          }
        },
        {
          title: "操作",
          width: 120,
          align: "center",
          render: (h, params) => {
            var html = [];

            if (this.Auth(["User", "blocked"])) {
              html.push(
                h("Button", {
                  props: {
                    type: "warning",
                    size: "small",
                    icon: "md-warning"
                  },
                  class: "mg-lr-xs",
                  on: {
                    click: () => {
                      this.blocked(params.row);
                    }
                  }
                },"封禁/解封")
              );
            }
            return h("div", html);
          }
        }
      ]
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      let params = {
        sort: this.sort,
        search_key: this.$refs.searcher.search_key,
        keywords: this.$refs.searcher.keywords,
        begin_date: this.begin_date,
        end_date: this.end_date,
        page: this.$refs.pagination.page,
        limit: this.$refs.pagination.limit
      };

      this.$http.get("/user/list", { params: params }).then(res => {
        this.total = res.total;
        this.data = res.data;
      });
    },
    changeSort(item) {
      this.sort = item.order == "normal" ? "" : item.key + "," + item.order;
      this.$refs.pagination.page = 1;
      this.loadData();
    },
  
    blocked(item) {
      this.$Modal.confirm({
        title: "封禁或解封",
        content: '确定要封禁或解封用户'+item.nickname+'吗?',
        onOk: () => {
          this.$http.post("/user/blocked/id/" + item.id).then(res => {
            this.loadData();
          });
        }
      });
    },
   
    filterDate(date) {
      this.begin_date = date[0];
      this.end_date = date[1];
      this.$refs.pagination.page = 1;
      this.loadData();
    },
    exportFile() {
      let params = {
        search_key: this.$refs.searcher.search_key,
        keywords: this.$refs.searcher.keywords,
        begin_date: this.begin_date,
        end_date: this.end_date,
        sort: this.sort,
        page: this.$refs.pagination.page,
        limit: this.$refs.pagination.limit,
        token: window.localStorage.getItem("token")
      };

      let url = this.$http.defaults.baseURL + "/user/export?";

      window.open(buildURL(url, params));
    }
  }
};
</script>